import React from 'react';
import ContactForm from '../ContactForm';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
const Footer = () => {
  return (
    <footer className="flex flex-col items-center justify-center bg-gradient-to-r from-sky-900 to-neutral-900 text-white py-8 rounded-t-2xl text-center" id="contact">
      <ContactForm />
      <div className="my-4 flex justify-center space-x-6 mb-4">
        <a href="https://www.linkedin.com/in/henrique-moura/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-500 transition duration-300">
          <FaLinkedin size={24} />
        </a>
        <a href="https://www.facebook.com/seu-perfil" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-600 transition duration-300">
          <FaFacebook size={24} />
        </a>
        <a href="https://www.instagram.com/seu-perfil" target="_blank" rel="noopener noreferrer" className="text-white hover:text-pink-500 transition duration-300">
          <FaInstagram size={24} />
        </a>
      </div>
      <p className="text-sm">
        © {new Date().getFullYear()} Powered by <span className="font-bold">HMTech</span>. Todos os direitos reservados.
      </p>
    </footer>
  );
};

export default Footer;