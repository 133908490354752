import React from 'react';

const technologies = [
  {
    name: 'React',
    description: 'Biblioteca para construção de interfaces dinâmicas e eficientes.',
    icon: 'https://cdn.worldvectorlogo.com/logos/react-2.svg',
  },
  {
    name: 'Node.js',
    description: 'Plataforma escalável para desenvolvimento de APIs e servidores.',
    icon: 'https://cdn.worldvectorlogo.com/logos/nodejs-icon.svg',
  },
  {
    name: 'HTML5',
    description: 'Base para criar sites com estrutura moderna e semântica.',
    icon: require('../../assets/images/HTML5_logo.png'),
  },
  {
    name: 'CSS',
    description: 'A comprehensive cloud computing platform by Amazon.',
    icon: 'https://cdn.worldvectorlogo.com/logos/css-3.svg',
  },
  {
    name: 'Tailwind CSS',
    description: 'Um framework CSS utilitário para desenvolvimento rápido de UI.',
    icon: 'https://cdn.worldvectorlogo.com/logos/tailwind-css-2.svg',
  },
  {
    name: 'Docker',
    description: 'Ferramenta de containerização para ambientes consistentes e otimizados.',
    icon: 'https://cdn.worldvectorlogo.com/logos/docker.svg',
  },
  {
    name: 'MySQL',
    description: 'Um sistema de gerenciamento de banco de dados relacional de código aberto.',
    icon: require('../../assets/images/logo-mysql.png'),
  },
  {
    name: 'MongoDB',
    description: 'Um banco de dados NoSQL para aplicações modernas.',
    icon: 'https://cdn.worldvectorlogo.com/logos/mongodb-icon-1.svg',
  },
  {
    name: 'AWS',
    description: 'Infraestrutura de computação em nuvem para escalabilidade e segurança.',
    icon: 'https://cdn.worldvectorlogo.com/logos/aws-2.svg',
  },

  // Adicione mais tecnologias aqui conforme necessário
];

const Technologies = () => {
  return (
    <section className="py-24 bg-gray-100" id="technologies">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-8">Tecnologias</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {technologies.map((tech, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
            >
              <img src={tech.icon} alt={`${tech.name} icon`} className="w-16 h-16 mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-center mb-2">{tech.name}</h3>
              <p className="text-gray-600 text-center">{tech.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Technologies;