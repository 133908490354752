import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [status, setStatus] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post('http://localhost:5000/send-email', formData)
            .then(response => {
                setStatus('E-mail enviado com sucesso!');
                setFormData({ name: '', email: '', message: '' });
            })
            .catch(error => {
                setStatus('Erro ao enviar o e-mail.');
                setFormData({ name: '', email: '', message: '' });

            });
    };

    return (<>
        <h2 className="text-2xl mb-4">Entre em Contato</h2>
        <form className="w-full flex flex-col justify-center items-center" onSubmit={handleSubmit}>
            <div className="w-[30%] mb-4">
                <input
                    type="text"
                    name="name"
                    placeholder='Nome'
                    value={formData.name}
                    onChange={handleInputChange}
                    className="w-full p-2 border text-black border-gray-300 rounded"
                    required
                />
            </div>
            <div className="w-[30%] mb-4">
                <input
                    type="email"
                    name="email"
                    placeholder='E-mail'
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full p-2 border text-black border-gray-300 rounded"
                    required
                />
            </div>
            <div className="w-[30%] mb-4">
                <textarea
                    name="message"
                    placeholder='Mensagem'
                    value={formData.message}
                    onChange={handleInputChange}
                    className="w-full p-2 border text-black border-gray-300 rounded"
                    required
                />
            </div>
            <button
                type="submit"
                className="bg-[#5D636F] text-white px-4 py-2 rounded hover:bg-blue-600 transition"
            >
                Enviar
            </button>
        </form>
        {status && <p className="mt-4">Mensagem enviada</p>}</>
    );
};

export default ContactForm;