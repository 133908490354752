import React from 'react';
import { Link } from 'react-scroll';

const Header = () => {
    return (
        <header className="fixed w-full bg-gradient-to-r from-sky-900 to-neutral-900 text-white py-4 z-50 rounded-b shadow-lg">
            <div className="container mx-10 flex justify-between items-center">
                <div href="#hero" className="flex justify-center items-center gap-3 text-2xl font-bold cursor-pointer">
                    <img alt='logo' className='w-12 h-12' src={require("../../assets/images/logo.png")} />
                    <a href="#hero" className="hover:text-gray-300 transition duration-300">
                        HMSolutions
                    </a>
                </div>

                {/* Menu */}
                <nav>
                    <ul className="flex space-x-8">
                        <li>
                            <Link to="hero" smooth={true} duration={700} className="hover:text-gray-300 transition duration-300 cursor-pointer">
                                Início
                            </Link>
                        </li>
                        <li>
                            <Link to="services" smooth={true} duration={700} className="hover:text-gray-300 transition duration-300 cursor-pointer">
                                Serviços
                            </Link>

                        </li>
                        <li>
                            <Link to="tecnologies" smooth={true} duration={700} className="hover:text-gray-300 transition duration-300 cursor-pointer">
                                Tecnologias
                            </Link>
                        </li>
                        <li>
                            <Link to="contact" smooth={true} duration={700} className="hover:text-gray-300 transition duration-300 cursor-pointer">
                                Contato
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;