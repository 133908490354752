import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Hero from './components/Hero';
// import Portfolio from './components/Portfolio';
import Services from './components/Services';
import Technologies from './components/Technologies';
import { WhatsappButton } from './components/WhatsappButton';

function App() {
  return (
    <div className='font-montserrat'>
      <Header />
      <section id="hero" className="pt-4">
        <Hero />
      </section>
      <section id="services">
        <Services />
      </section>
      <section id="tecnologies">
        <Technologies />
      </section>
      <Footer />
      <WhatsappButton />
    </div>
  );
}

export default App;
