import React from 'react';

const services = [
    { name: 'Contato Inicial e Reunião', description: 'Entender as necessidades do cliente e apresentar a empresa.', img: require('../../assets/images/meeting.jpg') },
    { name: 'Proposta e Escopo do Projeto', description: 'Formalizar a oferta e definir claramente o que será entregue.', img: require('../../assets/images/note-pencil.jpg') },
    { name: 'Desenvolvimento', description: 'Construir o site ou sistema conforme o design aprovado.', img: require('../../assets/images/code-icon.jpg') },
    { name: 'Entrega e Treinamento', description: 'Lançar o site ou sistema e treinar o cliente para utilizá-lo.', img: require('../../assets/images/training.png') },
    { name: 'Suporte Pós-Lançamento', description: 'Garantir uma transição suave e suporte contínuo.', img: require('../../assets/images/suport.png') },
];

const Services = () => {
    return (
        <div className="py-40 h-screen w-full bg-gradient-to-r from-cyan-500 to-blue-500 text-center">
            <h2 className="text-3xl font-bold mb-8">Processo de contratação</h2>
            <div className="flex m-24 justify-center space-x-16">
                {services.map((service, index) => (
                    <div
                        key={index}
                        className="bg-gray-50 p-8 gap-2 flex flex-col justify-center items-center  rounded-lg shadow-md hover:scale-105 transform transition-transform duration-300"
                    >
                        <img className="w-40" alt='meeting' src={service.img} />
                        <h3 className="text-xl text-nowrap font-semibold">{service.name}</h3>
                        <p className="mt-2 text-gray-600">{service.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Services;