import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const Hero = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [{
        title: "Leve sua empresa ao próximo nível com tecnologia sob medida.",
        subtitle: "Aumente a visibilidade, automatize processos e conquiste mais clientes com um site ou sistema web desenvolvido para as necessidades do seu negócio.",
        urlImage: "bg-[url('/src/assets/images/tech.jpg')]"
    },
    {
        title: "Escalabilidade e inovação ao alcance do seu negócio.",
        subtitle: "Com um site ou sistema web, sua empresa pode alcançar mais clientes, automatizar processos e crescer sem limites. Invista em tecnologia e fique à frente da concorrência.",
        urlImage: "bg-[url('/src/assets/images/scalebility.jpg')]"
    },
    {
        title: "Sua presença digital começa com um site que gera resultados.",
        subtitle: "Um site profissional é a base para aumentar sua credibilidade, conquistar clientes e se destacar no mercado digital.",
        urlImage: "bg-[url('/src/assets/images/hand-tech.jpg')]"
    },
    {
        title: "Seu negócio é único, sua solução também deve ser.",
        subtitle: "Desenvolvemos sites e sistemas web personalizados que impulsionam a eficiência, produtividade e a experiência do cliente.",
        urlImage: "bg-[url('/src/assets/images/equipe-reunida.jpg')]"
    },
    {
        title: "A melhor experiência para seus clientes começa aqui.",
        subtitle: "Desenvolvemos sites e sistemas intuitivos que melhoram a experiência do usuário, aumentando as chances de conversão e satisfação dos seus clientes.",
        urlImage: "bg-[url('/src/assets/images/cliente.jpg')]"
    },
    {
        title: "Prepare sua empresa para o futuro com a transformação digital.",
        subtitle: "A tecnologia está mudando a forma como fazemos negócios. Esteja preparado para liderar seu setor com um site ou sistema que se adapta ao seu crescimento.",
        urlImage: "bg-[url('/src/assets/images/design-moderna.jpg')]"
    }

    ]

    // Efeito para mudar os slides a cada 5 segundos
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 14000); // 5 segundos para mudar o slide

        return () => clearInterval(interval); // Limpa o intervalo quando o componente desmonta
    }, [slides.length, currentSlide]);

    const { urlImage, title, subtitle } = slides[currentSlide];



    return (
        <div className={`relative h-screen w-full bg-cover bg-center transition-all duration-1000 ease-in-out ${urlImage}`}>
            <div className="h-full w-full bg-black bg-opacity-50 flex flex-col justify-center items-center text-white text-center">
                <motion.h1
                    initial={{ opacity: 0, y: -100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="text-5xl text-[#FFF] font-bold mb-4"
                    key={title}
                >
                    {title}
                </motion.h1>
                <motion.p
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, delay: 0.5 }}
                    className="text-lg text-[#D9D9D9]"
                    key={subtitle}
                >
                    {subtitle}
                </motion.p>
            </div>
            <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 flex space-x-4">
                {slides.map((_, index) => (
                    <button
                        key={index}
                        className={`w-3 h-3 rounded-full ${index === currentSlide ? 'bg-white' : 'bg-gray-400'} transition-colors duration-300`}
                        onClick={() => setCurrentSlide(index)}
                        aria-label={`Go to slide ${index + 1}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default Hero;